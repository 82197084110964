import React from "react";
import Modal from "./Modal";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Button from "../button/Button";
import { hideAlert } from "../../store/alertReducer";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { MdOutlineCancel } from "react-icons/md";

const Alert = () => {
  const dispatch = useDispatch();

  const {
    visible,
    title,
    message,
    buttonText = "확인",
    onClick = () => {},
    cancel,
    print,
    componentRef,
    onHeaderCancel,
  } = useSelector((state) => state.alert);

  const handleClickButton = () => {
    onClick && onClick();
    dispatch(hideAlert());
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClickPrint = () => {
    onClick && onClick();
    handlePrint();
    dispatch(hideAlert());
  };

  const handleClickCancelButton = () => {
    dispatch(hideAlert());
  };

  return (
    <Modal visible={visible} zIndex={2000}>
      <AlertWrapper>
        <AlertTitle>
          {onHeaderCancel && (
            <div
              style={{
                position: "absolute",
                right: 0,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                onHeaderCancel();
                dispatch(hideAlert());
              }}>
              <span
                style={{
                  marginTop: 1,
                  alignSelf: "center",
                  fontSize: 14,
                  marginRight: 5,
                }}>
                오늘 하루 안보기
              </span>
              <MdOutlineCancel fontSize={20} />
            </div>
          )}
          {title}
        </AlertTitle>
        <AlertContent>
          {typeof message === "string"
            ? message?.split("")?.map((x, i) => {
                if (x === "\n") {
                  return <br key={i.toString()} />;
                } else {
                  return x;
                }
              })
            : message}
        </AlertContent>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: 8,
          }}>
          {cancel && (
            <AlertButton onClick={handleClickCancelButton}>
              {"취소"}
            </AlertButton>
          )}
          <AlertButton onClick={print ? handleClickPrint : handleClickButton}>
            {buttonText}
          </AlertButton>
        </div>
      </AlertWrapper>
    </Modal>
  );
};

const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 10px;
  border-radius: 10px;
  min-width: 200px;
  align-items: center;
`;
const AlertTitle = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  /* 
  display: flex;
  justify-content: space-between;
  align-items: center; */
`;
const AlertContent = styled.div`
  padding: 40px 20px 40px 20px;
  text-align-last: center;
  font-weight: bold;
`;
const AlertButton = styled(Button)`
  width: 100%;
`;

export default Alert;
