import React, { useState } from "react";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "store/loadingReducer";
import { objToQueryStr, validatePhone } from "libs/utils";
import Button from "../../components/button/Button";
import styled from "styled-components";
import { Input } from "@progress/kendo-react-inputs";
import Flex from "components/layout/Flex";
import { showAlert } from "store/alertReducer";

const PhoneNumberStep = ({ onNext, setStatusCustomer, setUserId }) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");

  const handleClickPhone = () => {
    if (!validatePhone(phone)) {
      dispatch(showAlert({ message: "연락처를 정확히 입력해주세요." }));
    } else {
      const param = { phone: phone };
      dispatch(showLoading());
      APIS.getFindPhone(objToQueryStr(param))
        .then(({ data: { success, data } }) => {
          if (success) {
            setStatusCustomer("current");
            setUserId(data);
            onNext();
          } else {
            setStatusCustomer("new");
            onNext();
          }
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleClickPhone();
    }
  };
  return (
    <div
      style={{
        display: "flex",
      }}>
      <RowKey>연락처</RowKey>
      <Flex>
        <Flex style={{ alignItems: "center" }}>
          <Input
            autoFocus
            style={{ width: 200 }}
            placeholder="숫자만 입력해주세요"
            value={phone}
            onKeyDown={handleKeyPress}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </Flex>
        <Button style={{ marginLeft: 5 }} onClick={handleClickPhone}>
          확인
        </Button>
      </Flex>
    </div>
  );
};

export default PhoneNumberStep;

const RowKey = styled.div`
font-weight : bold;
display: flex;
align-items: center;
margin-right: 10px;
`;
