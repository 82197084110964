import React, { useState } from "react";
import styled from "styled-components";
import { Dialog } from "@progress/kendo-react-dialogs";
import PhoneNumberStep from "./PhoneNumberStep";
import VerificationStep from "./VerificationStep";
import AccountRegistrationStep from "./AccountRegistrationStep";
import AppraisalStep from "./AppraisalStep";

const BizRequestModal = ({
  bizRequestVisible,
  setBizRequestVisible,
  fetchList,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const [statusCustomer, setStatusCustomer] = useState("");
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState({});

  const handleClickClose = () => {
    setBizRequestVisible(false);
    setCurrentStep(1);
    setUserData({});
    setUserId("");
    setStatusCustomer("");
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <PhoneNumberStep
            onNext={nextStep}
            setStatusCustomer={setStatusCustomer}
            setUserId={setUserId}
          />
        );
      case 2:
        return (
          <VerificationStep
            onNext={nextStep}
            statusCustomer={statusCustomer}
            userData={userData}
            setUserData={setUserData}
            userId={userId}
            setUserId={setUserId}
          />
        );
      case 3:
        return (
          <AccountRegistrationStep
            onNext={nextStep}
            userData={userData}
            userId={userId}
            setUserData={setUserData}
          />
        );
      case 4:
        return (
          <AppraisalStep
            userId={userId}
            setBizRequestVisible={setBizRequestVisible}
            fetchList={fetchList}
            handleClickClose={handleClickClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    bizRequestVisible && (
      <Dialog
        title={"감정평가 접수"}
        onClose={handleClickClose}
        visible={bizRequestVisible}
        width={currentStep !== 1 ? 1300 : ""}
        height={currentStep !== 1 ? 730 : ""}
        style={{
          zIndex: 200,
        }}>
        <Content>{renderStep()}</Content>
      </Dialog>
    )
  );
};

export default BizRequestModal;

const Content = styled.div`
  height: 100%;  
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0px 20px 0px 20px;
  align-items: center;
  justify-content: center;
`;
