import React, { useEffect, useState } from "react";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "store/loadingReducer";
import useInterval from "hooks/useInterval";
import Button from "../../components/button/Button";
import Flex from "components/layout/Flex";
import styled from "styled-components";
import { showAlert } from "store/alertReducer";
import { Input } from "@progress/kendo-react-inputs";
const VerificationStep = ({
  onNext,
  statusCustomer,
  userData,
  setUserData,
  userId,
  setUserId,
}) => {
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(-1);
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [stopInterval, setStopInterval] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");

  useEffect(() => {
    if (timeLeft === 0) {
      setErrorMessage(
        "인증번호가 만료되었습니다.\n인증번호를 다시 전송해주세요."
      );
      resetTimer();
    }
  }, [timeLeft]);

  //신규 회원 본인인증
  const handleClickAuth = () => {
    const data = { purpose: "SIGNUP" };
    APIS.postPrepareVerification(data).then(({ data: { requestId } }) => {
      window.open(
        `${process.env.REACT_APP_ADMIN_API_URL}/api/v1/biz/verification/self/start?requestId=${requestId}`,
        "_blank",
        "width=500,height=600"
      );

      // 메시지 수신 리스너 설정
      const handleMessage = (event) => {
        const { action, name, phone, requestId } = event.data;
        if (action === "closeAuthWindow") {
          setPhone(phone);
          setUserData({ name: name, phone: phone, requestId: requestId });
          window.removeEventListener("message", handleMessage);
        }
      };

      window.addEventListener("message", handleMessage);
    });
  };

  //알림톡 발송(약관 동의 요청)
  const handleClickSendTalk = () => {
    const serviceType = process.env.REACT_APP_SERVICE_TYPE;
    const data = {
      requestId: userData.requestId,
      phone: userData.phone,
      linkUrl:
        serviceType === "DEVELOPMENT"
          ? "https://dev-shop.kumbang.co.kr/talk/acceptTerms"
          : "https://shop.kumbang.co.kr/talk/acceptTerms",
    };
    dispatch(showLoading());
    APIS.postNotificationsTalk(data)
      .then(({ data: { success, message, data } }) => {
        if (success) {
          dispatch(showAlert({ message: "알림톡이 발송되었습니다." }));
        } else {
          dispatch(showAlert({ message }));
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  //인증번호 발송
  const handleClickPostSms = () => {
    const param = { id: userId };
    dispatch(showLoading());
    APIS.postRequestVerify(param)
      .then(({ data: { success, message } }) => {
        if (success) {
          dispatch(
            showAlert({
              message: "인증번호가 발송되었습니다.",
            })
          );
          startTimer();
          setErrorMessage("");
        } else {
          dispatch(
            showAlert({
              message: message,
            })
          );
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  //인증번호 확인
  const handleCheckAuthNum = () => {
    const param = { userId: userId, code: verifyCode };
    dispatch(showLoading());
    APIS.postConfirmVerify(param)
      .then(({ data: { success, message, data } }) => {
        if (success) {
          setStopInterval(true);
          setUserData(data);
          if (errorMessage !== "") {
            setErrorMessage("");
          }
        } else {
          setErrorMessage(message);
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  const resetTimer = () => {
    setTimeLeft(-1);
    setVerifyCode("");
  };

  const startTimer = () => {
    setTimeLeft(180);
    setVerifyCode("");
  };
  const handleClickNext = () => {
    const checkData = { phone: phone };
    dispatch(showLoading());
    APIS.postCheckTerms(checkData)
      .then(({ data: { success, message, data } }) => {
        if (success) {
          setUserId(data);
          onNext();
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleCheckAuthNum();
    }
  };

  useInterval(
    () => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    },
    stopInterval ? null : 1000
  );

  return (
    <div>
      {statusCustomer === "new" ? (
        <div
          style={{
            justifyContent: "space-between",
            width: "100%",
          }}>
          <Button
            onClick={handleClickAuth}
            disabled={phone !== ""}
            style={{ width: "100%" }}>
            본인인증
          </Button>
          <Row style={{ width: "100%" }}>
            <RowKey style={{ flex: "0 0 25%" }}>고객명</RowKey>
            <Input
              readOnly
              value={userData?.name}
              style={{ flex: "0 0 75%" }}
            />
          </Row>
          <Row>
            <RowKey style={{ flex: "0 0 25%" }}>전화번호</RowKey>
            <Input
              readOnly
              value={userData?.phone}
              style={{ flex: "0 0 75%" }}
            />
          </Row>
          <Row>
            <Button
              disabled={Object.keys(userData).length === 0 ? true : false}
              onClick={handleClickSendTalk}
              style={{ width: "100%", marginTop: "25px" }}>
              알림톡 발송(BIZ 약관 동의 요청)
            </Button>
          </Row>
          <Row style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ width: "100%" }}
              disabled={Object.keys(userData).length === 0 ? true : false}
              onClick={handleClickNext}>
              다음
            </Button>
          </Row>
        </div>
      ) : (
        <div
          style={{
            justifyContent: "space-between",
            width: "100%",
          }}>
          <Button
            style={{ width: "100%" }}
            disabled={stopInterval}
            onClick={handleClickPostSms}>
            인증번호 보내기
          </Button>
          <Row>
            <RowKey>인증번호</RowKey>
            <Row style={{ flex: "0 0 80%", position: "relative" }}>
              <Input
                value={verifyCode}
                placeholder="숫자 6자리 입력하세요."
                onChange={(e) => {
                  setVerifyCode(e.target.value);
                }}
                onKeyDown={handleKeyPress}
                style={{ paddingRight: "50px" }}
              />
              {timeLeft > -1 && (
                <TimeLeft>
                  {String(parseInt(String(timeLeft / 60))).padStart(2, "0")}:{" "}
                  {String(timeLeft % 60).padStart(2, "0")}
                </TimeLeft>
              )}
              <Button style={{ marginLeft: 5 }} onClick={handleCheckAuthNum}>
                확인
              </Button>
            </Row>
          </Row>
          <Error>{errorMessage}</Error>
          <Row style={{ width: "100%" }}>
            <RowKey>고객명</RowKey>
            <Input
              readOnly
              value={userData?.name}
              style={{ flex: "0 0 80%" }}
            />
          </Row>
          <Row>
            <Button
              style={{ width: "100%" }}
              disabled={Object.keys(userData).length === 0 ? true : false}
              onClick={() => onNext()}>
              다음
            </Button>
          </Row>
        </div>
      )}
    </div>
  );
};

export default VerificationStep;

const Row = styled(Flex)`
margin-top: 5px;
`;

const RowKey = styled.div`
font-weight : bold;
display: flex;
align-items: center;
flex: 0 0 20%;
`;

const Error = styled.div`
color: red;
font-weight : bold;
`;

const TimeLeft = styled.div`
  position: absolute;
  right: 50px; 
  top: 50%;
  transform: translateY(-50%);
  color: red;
  font-size: 14px;
  letter-spacing: -0.02em;
  pointer-events: none; 
`;
