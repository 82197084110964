import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { NumericTextBox, RadioButton } from "@progress/kendo-react-inputs";
import * as APIS from "../../libs/apis";
import { showAlert } from "store/alertReducer";
import { hideLoading, showLoading } from "store/loadingReducer";
import { useDispatch, useSelector } from "react-redux";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import cloneDeep from "lodash.clonedeep";
import { numberWithCommas } from "libs/utils";
import Modal from "components/common/Modal";
import styled from "styled-components";
import { MdOutlineCancel } from "react-icons/md";
import Flex from "components/layout/Flex";
import Button from "../../components/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { BizPurchaseConfig } from "libs/consts";

const AppraisalStep = ({
  userId,
  setBizRequestVisible,
  fetchList,
  handleClickClose,
}) => {
  const dispatch = useDispatch();
  const authReducer = useSelector((s) => s.auth);
  let { shopId } = authReducer;
  const [assetType, setAssetType] = useState("GOLD");
  const [productList, setProductList] = useState([]);
  const [totalGram, setTotalGram] = useState(0);
  const [valueModalVisible, setValueModalVisible] = useState(false);
  const [purchasePriceData, setPurchasePriceData] = useState({});

  useEffect(() => {
    dispatch(showLoading());
    APIS.getPurchaseProductList()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          assetType === "GOLD"
            ? setProductList(data.goldProduct)
            : setProductList(data.silverProduct);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((e) => {
        showAlert({ message: e.response.data.message });
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  }, [assetType]);

  useEffect(() => {
    if (productList.length === 0) {
      setTotalGram(0);
    } else {
      const totalWeight = productList.reduce((acc, item) => {
        return Number(
          (Number(acc) + Number(item.appraisalWeightGram)).toFixed(13)
        );
      }, 0);
      setTotalGram(totalWeight);
    }
  }, [productList]);

  const NumberInputCell = useCallback((props) => {
    const { dataIndex, dataItem, field } = props;
    const onChangeValue = (e) => {
      if (!!props.onChange) {
        props.onChange({
          dataIndex,
          value: e.value,
          field,
          syntheticEvent: e.syntheticEvent,
          dataItem,
        });
      }
    };
    return (
      <td style={{ textAlign: "-webkit-center" }}>
        <tr>
          <NumericTextBox
            onChange={onChangeValue}
            id={`${field}-${dataIndex}`}
            name={`${field}`}
            width={100}
            size="small"
            spinners={false}
            min={0}
            fillMode={"outline"}
            inputStyle={{ backgroundColor: "white" }}
          />
        </tr>
      </td>
    );
  }, []);

  const basicPctCell = useCallback(
    (props) => {
      const { dataIndex, dataItem, field } = props;
      const onChangeValue = (e) => {
        if (!!props.onChange) {
          props.onChange({
            dataIndex,
            value: e.value,
            field,
            syntheticEvent: e.syntheticEvent,
            dataItem,
          });
        }
      };
      const list = cloneDeep(productList);
      return (
        <td style={{ textAlign: "-webkit-center" }}>
          <tr>
            {list?.[dataIndex]?.isWeightRateChange === true ? (
              <div style={{ display: "flex" }}>
                <NumericTextBox
                  value={dataItem.basicPct}
                  onChange={onChangeValue}
                  id={`basicPct-${dataIndex}`}
                  name={"basicPct"}
                  width={100}
                  size="small"
                  spinners={false}
                  min={0}
                  fillMode={"outline"}
                  inputStyle={{ backgroundColor: "white" }}
                />
                <div
                  style={{
                    alignSelf: "center",
                    fontSize: "medium",
                  }}>
                  %
                </div>
              </div>
            ) : list?.[dataIndex]?.basicPct ? (
              `${numberWithCommas(list?.[dataIndex]?.basicPct)}%`
            ) : (
              "-"
            )}
          </tr>
        </td>
      );
    },
    [assetType, productList]
  );

  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "createdAt" ||
      props.field === "status" ||
      props.field === "managerName" ||
      props.field === "assetType" ||
      props.field === "purityTypeName" ||
      props.field === "name"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "appraiseGram" ||
      props.field === "basicPct" ||
      props.field === "productWeightGram" ||
      props.field === "appraisalWeightGram"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "right" }}>
          {numberWithCommas(props.dataItem[props.field])}
        </td>
      );
    }
    return cell;
  };

  const onItemChange = useCallback(
    (props) => {
      const { value, dataItem, field } = props;
      let copyData = cloneDeep(productList);
      const updatedItems = copyData.map((item) => {
        const basicPctValue = field === "basicPct" ? value : item.basicPct;
        const productWeightGramValue =
          field === "productWeightGram" ? value : item.productWeightGram;
        if (item.id === dataItem.id) {
          return {
            ...item,
            [field]: value,
            basicPct: basicPctValue,
            productWeightGram: productWeightGramValue,
            appraisalWeightGram:
              dataItem.assetType === "GOLD"
                ? calculateAppraisalWeight(
                    Math.floor(
                      Math.round(
                        Number(
                          (
                            (basicPctValue / 100) *
                            productWeightGramValue
                          ).toFixed(13)
                        ) * 10000
                      ) / 10
                    ) / 1000
                  )
                : calculateAppraisalWeight(
                    Math.floor(
                      Math.round(
                        Number(
                          (
                            (basicPctValue / 100) *
                            productWeightGramValue
                          ).toFixed(13)
                        ) * 10000
                      ) / 1000
                    ) / 10
                  ),
          };
        } else {
          return item;
        }
      });

      copyData = updatedItems;
      setProductList(copyData);
    },
    [assetType, productList]
  );
  // 환산중량 계산 함수
  const calculateAppraisalWeight = (num) => {
    let stringAppraisalWeightGram = String(num);

    // replaceAt 메서드 구현
    const replaceAt = (str, index, replacement) => {
      return str.substring(0, index) + replacement + str.substring(index + 1);
    };

    if (stringAppraisalWeightGram.includes(".")) {
      const numberDigits = stringAppraisalWeightGram.split(".")[1].length;
      if (
        (assetType === "GOLD" && numberDigits === 3) ||
        (assetType === "SILVER" && numberDigits === 1)
      ) {
        const lastNumber =
          stringAppraisalWeightGram[stringAppraisalWeightGram.length - 1];
        if (0 <= parseInt(lastNumber) && parseInt(lastNumber) <= 4) {
          stringAppraisalWeightGram = replaceAt(
            stringAppraisalWeightGram,
            stringAppraisalWeightGram.length - 1,
            "0"
          );
        } else if (5 <= parseInt(lastNumber) && parseInt(lastNumber) <= 9) {
          stringAppraisalWeightGram = replaceAt(
            stringAppraisalWeightGram,
            stringAppraisalWeightGram.length - 1,
            "5"
          );
        }
      }
    }
    return stringAppraisalWeightGram;
  };

  const handleOpenSelectValue = () => {
    const data = {
      shopId: shopId,
      assetType: assetType,
      totalWeightGram: totalGram, // 환산 총 중량
      purchaseProduct: productList
        ?.filter((x) => x.productWeightGram !== null)
        .map((item) => {
          return {
            productId: item.id, // 품목id
            purityType: item.purityType,
            purityTypeName: item.purityTypeName, // 구분
            name: item.name, // 품목
            basicPct: item.basicPct, // 기준비율
            productWeightGram: item.productWeightGram, // 측정 실중량
            appraisalWeightGram: item.appraisalWeightGram, // 환산 중량
            etcFeeKrw: item.etcFeeKrw, // 추가 차감
          };
        }),
    };
    dispatch(showLoading());
    APIS.postPurchasePrice(data)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setPurchasePriceData(data);
          setValueModalVisible(true);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          marginTop: 10,
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <div>
          <span
            style={{
              fontWeight: "bold",
              marginRight: 10,
            }}>
            자산타입
          </span>
          <RadioButton
            style={{
              color: "black",
            }}
            value={"GOLD"}
            label="금"
            checked={assetType === "GOLD"}
            onChange={(e) => {
              setAssetType(e.value);
            }}
          />
          <RadioButton
            style={{
              color: "black",
              marginLeft: 10,
            }}
            value={"SILVER"}
            label="은"
            checked={assetType === "SILVER"}
            onChange={(e) => {
              setAssetType(e.value);
            }}
          />
        </div>
        <div
          style={{
            fontWeight: "bold",
            alignSelf: "center",
          }}>
          환산 중량 총 합계:
          {`${totalGram && numberWithCommas(totalGram)}g`}
          <Button onClick={handleOpenSelectValue} style={{ marginLeft: 15 }}>
            입력 완료
          </Button>
        </div>
      </div>
      <Grid
        style={{
          width: "100%",
          marginTop: "5px",
        }}
        scrollable={"none"}
        data={productList || []}
        cellRender={cellRender}
        onItemChange={onItemChange}>
        <GridColumn title="공통">
          <GridColumn field="purityTypeName" title="구분" width={130} />
          <GridColumn field="name" title="품목" width={140} />
          <GridColumn
            field="basicPct"
            title="기준비율"
            cell={basicPctCell}
            width={120}
          />
          <GridColumn
            field="productWeightGram"
            title="측정 실중량(g)"
            cell={NumberInputCell}
          />
          <GridColumn
            field="appraisalWeightGram"
            title="환산 중량(g)"
            cell={(props) => {
              const { dataIndex } = props;
              return (
                <td style={{ width: 80, textAlign: "right" }}>
                  {productList?.[dataIndex]?.appraisalWeightGram !== null &&
                  productList?.[dataIndex]?.productWeightGram !== "" &&
                  productList?.[dataIndex]?.basicPct !== "" &&
                  productList?.[dataIndex]?.productWeightGram !== null
                    ? numberWithCommas(
                        productList?.[dataIndex]?.appraisalWeightGram
                      )
                    : "-"}
                </td>
              );
            }}
          />
        </GridColumn>
        <GridColumn title="위탁매입 전용">
          <GridColumn
            field="etcFeeKrw"
            title="추가차감/돈"
            cell={NumberInputCell}
          />
          <GridColumn
            field="pureFee"
            title="정제료/돈"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td> {numberWithCommas(dataItem.pureFee) || "-"}</td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="currentConsignmentPrice"
            title="적용단가/돈"
            cell={(props) => {
              const { dataItem } = props;
              const calculatedPrice =
                dataItem.currentMarketPrice !== null
                  ? dataItem.currentMarketPrice -
                    (dataItem.pureFee + dataItem.etcFeeKrw)
                  : null;
              return (
                <td style={{ width: 80, textAlign: "right" }}>
                  {calculatedPrice !== null
                    ? numberWithCommas(Math.max(0, calculatedPrice))
                    : "-"}
                </td>
              );
            }}
          />
        </GridColumn>
      </Grid>
      {valueModalVisible && (
        <SelectValueModal
          valueModalVisible={valueModalVisible}
          setValueModalVisible={setValueModalVisible}
          purchasePriceData={purchasePriceData}
          userId={userId}
          productList={productList}
          shopId={shopId}
          setBizRequestVisible={setBizRequestVisible}
          fetchList={fetchList}
          handleClickClose={handleClickClose}
        />
      )}
    </div>
  );
};

export default AppraisalStep;

const SelectValueModal = ({
  valueModalVisible,
  setValueModalVisible,
  purchasePriceData,
  userId,
  productList,
  shopId,
  fetchList,
  handleClickClose,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [gridData, setGridData] = useState([]);
  const [selected, setSelected] = useState("");
  const [step, setStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    assetType,
    appraisalWeightGram,
    directShopFeePercent, //예상 대리점 수수료율 (%)
    directSuggestDon,
    directMarketDon,
    currentConsignmentPrice, //위탁매입 시세
    expectedDirectSuggestCustomer, //고객 지급 금액 (추천가)
    expectedDirectMarketCustomer, //고객 지급 금액 (시장가)
    expectedConsignmentCustomer, //고객 지급 금액(위탁매입)
    expectedDirectSuggestShop, // 다이렉트 추천가 대리점 지급 예상 금액
    expectedDirectMarketShop, // 다이렉트 시장가 대리점 지급 예상 금액
    expectedConsignmentShop, // 위탁매입 예상 대리점 지급 금액
    directSuggestGram, //1g 가격(추천가)
    expectedSuggestTradeKrw, //예상 금액 (추천가)
    expectedMarketTradeKrw, //예상 금액 (시장가)
    expectedMarketCustomerFee, // 예상 수수료 (시장가)
    expectedSuggestCustomerFee, // 예상 수수료 (추천가)
  } = purchasePriceData;

  const handleClickPurchase = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const data = {
      shopId: shopId,
      assetType: assetType,
      totalWeightGram: appraisalWeightGram, // 환산 총 중량
      userId: userId, // 사용자id
      bizServiceType: selected, // 접수 방법
      orderPrice:
        selected === "DIRECT_SUGGEST"
          ? expectedDirectSuggestCustomer
          : selected === "DIRECT_MARKET"
          ? expectedDirectMarketCustomer
          : currentConsignmentPrice, //시세  - 다이렉트 접수 시 한돈 가격(시장가, 추천가)
      purchaseProduct: productList
        ?.filter((x) => x.productWeightGram !== null)
        .map((item, index) => {
          return {
            seq: index + 1,
            productId: item.id, // 품목id
            purityType: item.purityType,
            purityTypeName: item.purityTypeName, // 구분
            name: item.name, // 품목
            basicPct: item.basicPct, // 기준비율
            productWeightGram: item.productWeightGram, // 측정 실중량
            appraisalWeightGram: item.appraisalWeightGram, // 환산 중량
            etcFeeKrw: item.etcFeeKrw || 0, // 추가 차감
            pureFee: item.pureFee, // 정제료(원/돈)
            currentConsignmentPrice: item.currentConsignmentPrice, // 적용단가(원/돈)
          };
        }),
    };
    dispatch(showLoading());
    APIS.postPurchase(data)
      .then(({ data: { success, data, message } }) => {
        setValueModalVisible(false);
        dispatch(
          showAlert({
            message: success ? "접수 완료 되었습니다." : message,
            onClick: async () => {
              const selectedType =
                selected === "BIZ_CONSIGNMENT"
                  ? "BIZ_CONSIGNMENT"
                  : "BIZ_DIRECT";
              await handleValueChange("bizServiceType", selectedType);
              handleClickClose();
            },
          })
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        dispatch(hideLoading());
      });
  };

  const handleClickCloseModal = () => {
    setValueModalVisible(false);
  };

  useEffect(() => {
    const transformData = (apiData, configMapping) => {
      const result = configMapping.map((config) => {
        return {
          category: config.category,
          name: config.name,
          customerPrice: apiData[config.customerPrice],
          shopPrice: apiData[config.shopPrice],
          isConsignment: apiData["isConsignment"],
          isDirectMarket: apiData["isDirectMarket"],
          isDirectSuggest: apiData["isDirectSuggest"],
        };
      });

      return result;
    };
    // 변환된 데이터
    const transformedData = transformData(purchasePriceData, BizPurchaseConfig);
    setGridData(transformedData);
  }, []);

  const customCell = (props, type = "") => {
    const { dataItem } = props;
    return (
      <td>
        <tr style={{ height: "3vh" }}>
          <td
            style={{
              cursor: "default",
              width: "100vw",
              textAlign: "center",
              height: "3vh",
            }}>
            {dataItem[`${type}`] ? numberWithCommas(dataItem[`${type}`]) : "-"}
          </td>
        </tr>
      </td>
    );
  };
  const handleClickNext = () => {
    if (selected !== "") {
      setStep(step + 1);
    }
  };

  const handleValueChange = (key, value, more) => {
    const updatedState = {
      ...location.state.appraisal,
      [key]: value || "",
      ...more,
    };

    navigate(location.pathname, {
      replace: true,
      state: {
        appraisal: updatedState,
      },
    });
    fetchList(updatedState);
  };

  return (
    <Modal visible={valueModalVisible}>
      <Wrapper>
        <Header>
          <CancelBtn onClick={handleClickCloseModal} />
        </Header>
        <Content>
          {step === 0 ? (
            <div>
              <Grid
                data={gridData}
                style={{
                  width: "100%",
                  marginTop: 5,
                }}
                scrollable={"none"}>
                <GridColumn
                  width={130}
                  cell={(e) => {
                    const { dataItem } = e;
                    return (
                      <td style={{ display: "flex", alignItems: "center" }}>
                        <RadioButton
                          checked={selected === dataItem.category}
                          value={dataItem.category}
                          disabled={
                            dataItem.category === "DIRECT_SUGGEST"
                              ? !dataItem.isDirectSuggest
                              : dataItem.category === "BIZ_CONSIGNMENT"
                              ? !dataItem.isConsignment
                              : !dataItem.isDirectMarket
                          }
                          onChange={(e) => {
                            setSelected(e.value);
                          }}
                          label={dataItem.name}
                        />
                      </td>
                    );
                  }}
                />
                <GridColumn
                  title="고객지급 금액"
                  width={130}
                  cell={(props) => customCell(props, "customerPrice")}
                />
                <GridColumn
                  title="대리점 수수료"
                  width={130}
                  cell={(props) => customCell(props, "shopPrice")}
                />
              </Grid>
              <Flex style={{ justifyContent: "flex-end", marginTop: 5 }}>
                <div>
                  <Button disabled={selected === ""} onClick={handleClickNext}>
                    다음
                  </Button>
                </div>
              </Flex>
            </div>
          ) : (
            <div>
              <div
                style={{ fontWeight: "bold", fontSize: "21px" }}>{`${assetType
                .replace("GOLD", "금")
                .replace(
                  "SILVER",
                  "은"
                )} ${appraisalWeightGram}g 판매합니다.`}</div>
              <div style={{ fontWeight: "bold" }}>아래의 내용이 맞나요?</div>
              <div style={{ marginTop: 15 }}>
                <DataRow>
                  <DataKey>자산</DataKey>
                  <DataValue>
                    {assetType.replace("GOLD", "금").replace("SILVER", "은")}
                  </DataValue>
                </DataRow>
                <DataRow>
                  <DataKey>주문 방식</DataKey>
                  <DataValue>
                    {selected
                      .replace("DIRECT_MARKET", "시장가")
                      .replace("DIRECT_SUGGEST", "추천가")
                      .replace("BIZ_CONSIGNMENT", "위탁매입")}
                  </DataValue>
                </DataRow>
                {selected === "BIZ_CONSIGNMENT" ? (
                  <DataRow>
                    <DataKey>위탁매입 시세 (돈)</DataKey>
                    <DataValue number>
                      {`${numberWithCommas(currentConsignmentPrice)} 원`}
                    </DataValue>
                  </DataRow>
                ) : (
                  <DataRow>
                    <DataKey>1g 가격</DataKey>
                    {selected === "DIRECT_MARKET" ? (
                      <DataValue>즉시 체결 가격</DataValue>
                    ) : (
                      <DataValue number>{`${numberWithCommas(
                        directSuggestGram
                      )} 원`}</DataValue>
                    )}
                  </DataRow>
                )}
                <DataRow>
                  <DataKey>판매 중량</DataKey>
                  <DataValue number>{`${appraisalWeightGram} g`}</DataValue>
                </DataRow>
                {selected !== "BIZ_CONSIGNMENT" && (
                  <>
                    <DataRow>
                      <DataKey>{`판매 ${
                        selected === "DIRECT_MARKET" ? "예상" : ""
                      } 금액`}</DataKey>
                      <DataValue number>
                        {`${
                          selected === "DIRECT_MARKET"
                            ? numberWithCommas(expectedMarketTradeKrw)
                            : numberWithCommas(expectedSuggestTradeKrw)
                        } 원`}
                      </DataValue>
                    </DataRow>
                    <DataRow>
                      <DataKey>
                        {`${selected === "DIRECT_MARKET" ? "예상" : ""}수수료`}
                      </DataKey>
                      <DataValue number>
                        {`- ${
                          selected === "DIRECT_MARKET"
                            ? numberWithCommas(expectedMarketCustomerFee)
                            : numberWithCommas(expectedSuggestCustomerFee)
                        } 원`}
                      </DataValue>
                    </DataRow>
                  </>
                )}
                <DataRow>
                  <DataKey>
                    {`고객 지급 ${
                      selected === "DIRECT_MARKET" ? "예상" : ""
                    } 금액`}
                  </DataKey>
                  <DataValue number>
                    {`${
                      selected === "DIRECT_MARKET"
                        ? numberWithCommas(expectedDirectMarketCustomer)
                        : selected === "DIRECT_SUGGEST"
                        ? numberWithCommas(expectedDirectSuggestCustomer)
                        : numberWithCommas(expectedConsignmentCustomer)
                    } 원`}
                  </DataValue>
                </DataRow>
                <DataRow>
                  <DataKey>{`${
                    selected === "DIRECT_MARKET" ? "예상" : ""
                  }대리점 수수료 ${
                    selected !== "BIZ_CONSIGNMENT"
                      ? `(${directShopFeePercent}%)`
                      : ""
                  }`}</DataKey>
                  <DataValue number>
                    {`${
                      selected === "DIRECT_MARKET"
                        ? numberWithCommas(expectedDirectMarketShop)
                        : selected === "DIRECT_SUGGEST"
                        ? numberWithCommas(expectedDirectSuggestShop)
                        : numberWithCommas(expectedConsignmentShop)
                    } 원`}
                  </DataValue>
                </DataRow>
                <div
                  style={{ fontWeight: "bold", margin: "25px 0px 15px 0px" }}>
                  {selected === "DIRECT_MARKET"
                    ? "승인 시, 자동으로 주문완료되며 돌려받을 수 없습니다."
                    : selected === "DIRECT_SUGGEST"
                    ? "승인 시, 자동으로 주문됩니다."
                    : "승인 시, 매입이 완료되며 돌려받을 수 없습니다."}
                </div>
              </div>
              <Flex style={{ gap: 5 }}>
                <Button
                  style={{ flex: "0 0 50%", width: "100%" }}
                  onClick={() => {
                    setStep(step - 1);
                  }}>
                  취소
                </Button>
                <Button
                  style={{ flex: "0 0 50%", width: "100%" }}
                  disabled={isSubmitting}
                  onClick={handleClickPurchase}>
                  승인
                </Button>
              </Flex>
            </div>
          )}
        </Content>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 40px;
  border-radius: 10px;
  min-width: 300px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`;

const Content = styled.div`
  width : 100%;  
  margin-top: 5px;
  padding : 10px;
`;

const DataRow = styled(Flex)`
  margin-bottom: 5px;
  border-bottom: 1px solid;
`;
const DataKey = styled.div`
flex: 0 0 50%; 
width: 100%; 
font-weight: bold;
`;

const DataValue = styled.div`
flex: 0 0 50%; 
width: 100%; 
text-align: ${(props) => (props.number ? "end" : "")};
`;

const CancelBtn = styled(MdOutlineCancel)`
cursor: pointer; 
width: 25px; 
height: 25px;
`;
