import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as APIS from "../../libs/apis";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "store/alertReducer";
import { hideLoading, showLoading } from "store/loadingReducer";
import { numberWithCommas } from "libs/utils";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { BizRePurchaseConfig } from "libs/consts";
import { RadioButton } from "@progress/kendo-react-inputs";

const BizTransactionModal = ({
  bizTransactionVisible,
  setBizTransactionVisible,
  bizTradeSelectType,
  detailData,
  bizPurchasePrice,
  fetchDetail,
}) => {
  const dispatch = useDispatch();
  const authReducer = useSelector((s) => s.auth);
  let { shopId } = authReducer;
  const [gridData, setGridData] = useState([]);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (bizTradeSelectType === "ORDER") {
      dispatch(showLoading());
      const productList =
        detailData.assetType === "GOLD"
          ? detailData.goldProduct
          : detailData.silverProduct;
      const data = {
        shopId: shopId,
        assetType: detailData.assetType,
        totalWeightGram: detailData.appraisalWeightGram, // 환산 총 중량
        purchaseProduct: productList
          ?.filter((x) => x.productWeightGram !== null)
          .map((item) => {
            return {
              productId: item.id, // 품목id
              purityType: item.purityType,
              purityTypeName: item.purityTypeName, // 구분
              name: item.name, // 품목
              basicPct: item.basicPct, // 기준비율
              productWeightGram: item.productWeightGram, // 측정 실중량
              appraisalWeightGram: item.appraisalWeightGram, // 환산 중량
              etcFeeKrw: item.etcFeeKrw, // 추가 차감
            };
          }),
      };
      APIS.postPurchasePrice(data)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            const transformedData = transformData(data, BizRePurchaseConfig);
            setGridData(transformedData);
          } else {
            dispatch(showAlert({ message: message }));
          }
        })
        .finally(() => {
          dispatch(hideLoading());
        });

      const transformData = (apiData, configMapping) => {
        const result = configMapping.map((config) => {
          return {
            category: config.category,
            name: config.name,
            customerPrice: apiData[config.customerPrice],
            shopPrice: apiData[config.shopPrice],
            isConsignment: apiData["isConsignment"],
            isDirectMarket: apiData["isDirectMarket"],
            isDirectSuggest: apiData["isDirectSuggest"],
          };
        });

        return result;
      };
    }
  }, [bizTradeSelectType, bizTransactionVisible]);

  const modalTitle =
    bizTradeSelectType === "CANCEL"
      ? "주문 취소"
      : bizTradeSelectType === "ORDER"
      ? "주문 요청"
      : bizTradeSelectType === "WITHDRAW"
      ? "출금 요청"
      : bizTradeSelectType === "RETURN"
      ? "반송"
      : "";

  const handleClickClose = () => {
    setBizTransactionVisible(false);
  };

  const handleClickTrade = () => {
    let orderPrice;
    if (bizTradeSelectType === "ORDER") {
      orderPrice = gridData.find(
        (item) => item.category === selected
      ).customerPrice;
    }
    // 취소 데이터
    const cancelData = {
      shopId: detailData.shop.id,
      userId: detailData.user.id,
      requestId: detailData.id,
    };
    //주문 데이터
    const orderData = {
      shopId: detailData.shop.id,
      userId: detailData.user.id,
      requestId: detailData.id,
      bizServiceType: selected,
      orderPrice: orderPrice,
    };
    //출금요청 데이터
    const withdrawData = {
      requestId: detailData.id,
      userId: detailData.user.id,
      withdrawPrice: detailData.availableWithdrawal, // 출금 요청 금액(수수료 뺀금액)
    };
    //반송 데이터
    const returnData = {
      reqStatus: "RETURN_REQUEST",
      bizServiceType: detailData.serviceType,
    };

    const api =
      bizTradeSelectType === "CANCEL"
        ? APIS.patchDirectCancel(cancelData)
        : bizTradeSelectType === "ORDER"
        ? APIS.patchDirectOrder(orderData)
        : bizTradeSelectType === "WITHDRAW"
        ? APIS.postDirectWithdraw(withdrawData)
        : bizTradeSelectType === "RETURN"
        ? APIS.putPurchaseReturn(detailData.id, returnData)
        : "";

    dispatch(showLoading());
    api
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            showAlert({
              message: "처리되었습니다.",
              onClick: () => {
                fetchDetail(detailData.id);
              },
            })
          );
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => {
        dispatch(hideLoading());
        setBizTransactionVisible(false);
      });
  };

  const customCell = (props, type = "") => {
    const { dataItem } = props;
    return (
      <td>
        <tr style={{ height: "3vh" }}>
          <td
            style={{
              cursor: "default",
              width: "100vw",
              textAlign: "center",
              height: "3vh",
            }}>
            {dataItem[`${type}`] ? numberWithCommas(dataItem[`${type}`]) : "-"}
          </td>
        </tr>
      </td>
    );
  };

  const render = () => {
    switch (bizTradeSelectType) {
      case "CANCEL":
        return (
          <div>
            <InfoP>주문을 취소하시겠어요?</InfoP>
            <InfoP>주문을 취소하시면 전량 주문 취소됩니다.</InfoP>
          </div>
        );
      case "ORDER":
        return (
          <div>
            <Grid
              data={gridData}
              style={{
                width: "100%",
                marginTop: 5,
              }}
              scrollable={"none"}>
              <GridColumn
                width={130}
                cell={(e) => {
                  const { dataItem } = e;
                  return (
                    <td style={{ display: "flex", alignItems: "center" }}>
                      <RadioButton
                        checked={selected === dataItem.category}
                        value={dataItem.category}
                        disabled={
                          dataItem.category === "DIRECT_SUGGEST"
                            ? !dataItem.isDirectSuggest
                            : dataItem.category === "BIZ_CONSIGNMENT"
                            ? !dataItem.isConsignment
                            : !dataItem.isDirectMarket
                        }
                        onChange={(e) => {
                          setSelected(e.value);
                        }}
                        label={dataItem.name}
                      />
                    </td>
                  );
                }}
              />
              <GridColumn
                title="고객지급 금액"
                width={130}
                cell={(props) => customCell(props, "customerPrice")}
              />
              <GridColumn
                title="대리점 수수료"
                width={130}
                cell={(props) => customCell(props, "shopPrice")}
              />
            </Grid>
          </div>
        );
      case "WITHDRAW":
        return (
          <div>
            <InfoSpan>출금하시겠어요?</InfoSpan>
            <InfoP>
              출금요청 금액 :{" "}
              {`${numberWithCommas(detailData.availableWithdrawal)} 원`}
            </InfoP>
          </div>
        );
      case "RETURN":
        return (
          <div>
            <InfoSpan>반송하시겠어요?</InfoSpan>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    bizTransactionVisible && (
      <Dialog
        onClose={handleClickClose}
        title={modalTitle}
        visible={bizTransactionVisible}
        style={{ zIndex: 200 }}>
        <Wrapper>
          <Content>{render()}</Content>
        </Wrapper>
        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={handleClickClose}>
            닫기
          </button>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={handleClickTrade}>
            확인
          </button>
        </DialogActionsBar>
      </Dialog>
    )
  );
};

export default BizTransactionModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0px 20px 20px 20px;
`;

const Content = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
margin-top: 20px;
`;

const InfoSpan = styled.span`
  font-weight : bold;
  font-size: 17px;
`;

const InfoP = styled.p`
  font-weight : bold;
  font-size: 15px;
`;
