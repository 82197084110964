import queryString from "query-string";
import moment from "moment-timezone";
import { PHONE_CODE } from "./consts";

export const objToQueryStr = (obj) => {
  Object.keys(obj).map((key) => {
    if (obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  });

  return "?" + queryString.stringify(obj || {});
};

export const formatTime = (date = new Date(), format = "YYYY-MM-DD") => {
  if (date) {
    return moment(date).format(format);
  } else {
    return "";
  }
};

export function numberWithCommas(x) {
  if (x) {
    let onlyNumber = x.toString().replace(/,/gi, "");
    let parts = onlyNumber.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  } else if (x === "") {
    return "";
  } else if (x === 0) {
    return "0";
  } else {
    return "0";
  }
}

export const numFormat = (num) => {
  if (num) {
    return Number(num).toLocaleString();
  } else {
    return "0";
  }
};

export const getPurityLabel = (purity, type) => {
  if (type === "gold" || type === "금") {
    switch (String(purity)) {
      case "1":
        return "14K(585)";
      case "2":
        return "18K(750)";

      case "3":
        return "24K(999)";
    }
  } else {
    switch (String(purity)) {
      case "1":
        return "기타";
      case "2":
        return "925";
      case "3":
        return "999이상";
    }
  }
};

export const formatPhone = (v) => {
  if (v) {
    return v.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  } else {
    return "-";
  }
};

export function getNoProcessLabel({ status, approvedAppraise, method }) {
  if (status.length > 0) {
    if (status[status.length - 1].status === "반송대기") {
      return "반송대기";
    }
    if (status[status.length - 1].status === "승인대기") {
      return "승인대기";
    }
    if (
      status.filter((x) => x.status === "감정완료")[0] &&
      !status.filter((x) => x.status === "반송반려")[0] &&
      status.filter((x) => x.status === "반송신청")[0] &&
      !status.filter((x) => x.status === "반송완료")[0]
    ) {
      return "반송대기";
    } else if (
      method !== "택배" &&
      status.filter((x) => x.status === "감정완료")[0] &&
      !approvedAppraise
    ) {
      return "등록대기";
    } else if (method === "택배") {
      if (status[status.length - 1].status === "접수승인") {
        return "감정대기";
      }
      return "접수대기";
    } else if (
      method !== "택배" &&
      status[status.length - 1].status === "접수"
    ) {
      return "감정대기";
    } else {
      return "-";
    }
  }
}

export function createIndex(state, index) {
  const recentPage = state?.page;
  const recentSize = state?.size;

  return recentPage * recentSize + index + 1;
}

export function unComma(x) {
  let parts = x.toString().split(".");
  if (typeof x === "string") {
    if (parts[0].includes(",")) {
      parts[0] = parts[0].toString().replace(/[^\d]+/g, "");
    }
  }
  return parts.join(".");
}
export const validatePhone = (phone) => {
  const phoneCodePattern = PHONE_CODE.join("|");
  const regex = new RegExp(`^(${phoneCodePattern})[0-9]{3,4}[0-9]{4}$`);
  return regex.test(phone);
};
