const STORAGE_PREPARE = "kumbang.manager.";
const STORAGE_KEYS = {
  accessToken: "accessToken",
  tableSize: "tableSize",
};

export const STORAGE_KEY = {};
for (let key in STORAGE_KEYS) {
  STORAGE_KEY[key] = STORAGE_PREPARE + STORAGE_KEYS[key];
}

export default {
  s3BaseUrl: process.env.REACT_APP_S3_BASE_URL,
};

export const PHONE_CODE = [
  "010",
  "011",
  "016",
  "017",
  "018",
  "019",
  "02",
  "031",
  "032",
  "033",
  "041",
  "042",
  "043",
  "044",
  "051",
  "052",
  "053",
  "054",
  "055",
  "061",
  "062",
  "063",
  "064",
];

export const BizPurchaseConfig = [
  {
    category: "DIRECT_MARKET",
    name: "다이렉트 시장가",
    customerPrice: "expectedDirectMarketCustomer", // 다이렉트 시장가 고객 지급 예상 금액
    shopPrice: "expectedDirectMarketShop", // 다이렉트 시장가 대리점 지급 예상 금액
  },
  {
    category: "DIRECT_SUGGEST",
    name: "다이렉트 추천가",
    customerPrice: "expectedDirectSuggestCustomer", // 다이렉트 추천가 고객 지급 예상 금액
    shopPrice: "expectedDirectSuggestShop", // 다이렉트 추천가 대리점 지급 예상 금액
  },
  {
    category: "BIZ_CONSIGNMENT",
    name: "위탁매입",
    customerPrice: "expectedConsignmentCustomer", // 위탁매입 예상 고객 지급 금액
    shopPrice: "expectedConsignmentShop", // 위탁매입 예상 대리점 지급 금액
  },
];

export const BizRePurchaseConfig = [
  {
    category: "DIRECT_MARKET",
    name: "다이렉트 시장가",
    customerPrice: "expectedDirectMarketCustomer", // 다이렉트 시장가 고객 지급 예상 금액
    shopPrice: "expectedDirectMarketShop", // 다이렉트 시장가 대리점 지급 예상 금액
  },
  {
    category: "DIRECT_SUGGEST",
    name: "다이렉트 추천가",
    customerPrice: "expectedDirectSuggestCustomer", // 다이렉트 추천가 고객 지급 예상 금액
    shopPrice: "expectedDirectSuggestShop", // 다이렉트 추천가 대리점 지급 예상 금액
  },
];
